<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="vendorUserTable"
          title="자체 근무인원"
          tableId="vendorUserTable"
          :columns="grid.columns"
          :data="grid.data"
          :editable="editable"
          :gridHeight="setheight"
          :usePaging="false"
          :filtering="false"
          :hideBottom="true"
          :columnSetting="false"
          selection="multiple"
          rowKey="userId"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addselect" />
              <c-btn v-if="editable" label="삭제" icon="remove" @btnClicked="removerow" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'project-attend-vendor',
  props: {
    param: {
      type: Object,
      default: () => ({
        plantCd: '',
        updateFlag: '',
      }),
    },
    height: {
      type: Number,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '성명',
            align: 'center',
            sortable: true
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: '직책',
            align: 'center',
            sortable: true
          },
          {
            name: 'spotName',
            field: 'spotName',
            label: '직위',
            align: 'center',
            sortable: true
          },
        ],
        data: [],
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      editable: true,
      selectedVendor: {},
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
  },
  computed: {
    setheight() {
      if (this.height === 'auto') return;
      let numHeight = Number(this.height)
      if (numHeight <= 600) {
        numHeight = 836
      }
      numHeight = numHeight - 135

      return numHeight + 'px';
    }
  },
  methods: {
    init() {
      if(this.$store.getters.user.innerFlag === 'N') {
        this.editable = false;
      } else {
        this.editable = this.$route.meta.editable;
      }
      // api scope
      this.listUrl = selectConfig.pjm.vendorUser.self.url;
      this.saveUrl = transactionConfig.pjm.vendorUser.save.url;
      this.deleteUrl = transactionConfig.pjm.vendorUser.delete.url;

      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        innerFlag: 'Y',
        // managerFlag: 'N',
        plantCd: this.param.plantCd,
        compnayCd: this.$store.getters.user.compnayCd,
      }
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    addselect() {
      this.popupOptions.title = '사용자 검색'; // 사용자
      this.popupOptions.param = {
        type: 'multiple',
        isInUserAll: true,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid.data, { userId: item.userId }) === -1) {
            this.grid.data.splice(0, 0, {
              companyCd: this.$store.getters.user.companyCd,
              plantCd: this.param.plantCd,
              userId: item.userId,
              userName: item.userName,
              deptCd: item.deptCd,
              deptName: item.deptName,
              jobName: item.jobName,
              spotName: item.spotName,
              innerFlag: 'Y',
              editFlag: 'U'
            })
          }
        })
        this.$http.url = this.saveUrl;
        this.$http.type = 'POST';
        this.$http.param = this.grid.data;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
        });
      }
    },
    removerow() {
      let selectData = this.$refs['vendorUserTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(selectData, _item => {
              _item.plantCd = this.param.plantCd
            })
            this.$http.url = this.$format(this.deleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getVendorUserList({ vendorCd: this.parentVendorCd })
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.getList();
              this.$refs['vendorUserTable'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
